var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guidance"},[_c('div',{staticClass:"progress-step-content px-10"},[_c('div',{staticClass:"step-box",style:(_vm.e1 === 1 ? 'background: var(--theme_primary)' : 'background: transparent')},[_c('v-icon',{attrs:{"size":"18","color":"#FFFFFF"}},[_vm._v(" person ")])],1),_c('span',{staticClass:"solid-line"}),_c('div',{staticClass:"step-box",style:((_vm.e1 === 2 ? 'background: var(--theme_primary)' : _vm.e1 > 2 ? 'background: transparent' : 'background: rgba(255, 255, 255, 0.2)'))},[_c('v-icon',{attrs:{"size":"18","color":(_vm.e1 >= 2 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')}},[_vm._v(" groups ")])],1),_c('span',{staticClass:"solid-line"}),_c('div',{staticClass:"step-box",style:((_vm.e1 === 3 ? 'background: var(--theme_primary)' : _vm.e1 > 3 ? 'background: transparent' : 'background: rgba(255, 255, 255, 0.2)'))},[_c('v-icon',{attrs:{"size":"18","color":(_vm.e1 >= 3 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')}},[_vm._v(" account_balance ")])],1),_c('span',{staticClass:"solid-line"}),_c('div',{staticClass:"step-box",style:(_vm.e1 === 4 ? 'background: var(--theme_primary)' : 'background: rgba(255, 255, 255, 0.2)')},[_c('v-icon',{attrs:{"size":"18","color":(_vm.e1 === 4 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')}},[_vm._v(" playlist_add_check ")])],1)]),_c('div',{staticClass:"main-panel"},[(_vm.e1 === 1 && _vm.assist.tool.isNotEmpty(_vm.layouts.data.personalSectionId))?_c('wap-guidance-person',{directives:[{name:"page-behavior",rawName:"v-page-behavior",value:({eventSource: _vm.assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_PERSONAL_INFORMATION.value, path: _vm.$route.path}),expression:"{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_PERSONAL_INFORMATION.value, path: $route.path}"}],attrs:{"actionParameters":{
              sectionId: _vm.layouts.data.personalSectionId
            }},on:{"verification":_vm.verificationHandler,"continue":_vm.continueHandler}}):_vm._e(),(_vm.e1 === 2)?_c('wap-guidance-employment',{directives:[{name:"page-behavior",rawName:"v-page-behavior",value:({eventSource: _vm.assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_EMPLOYMENT_INFORMATION.value, path: _vm.$route.path}),expression:"{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_EMPLOYMENT_INFORMATION.value, path: $route.path}"}],attrs:{"actionParameters":{
              sectionId: _vm.layouts.data.employmentSectionId
            }},on:{"back":_vm.backHandler,"verification":_vm.verificationHandler,"continue":_vm.continueHandler}}):_vm._e(),(_vm.e1 === 3)?_c('wap-guidance-bank',{directives:[{name:"page-behavior",rawName:"v-page-behavior",value:({eventSource: _vm.assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_BANK_INFORMATION.value, path: _vm.$route.path}),expression:"{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_BANK_INFORMATION.value, path: $route.path}"}],attrs:{"actionParameters":{
              sectionId: _vm.layouts.data.bankSectionId
            }},on:{"verification":_vm.verificationHandler,"back":_vm.backHandler,"continue":_vm.continueHandler}}):_vm._e(),(_vm.e1 === 4)?_c('wap-guidance-decision-logic',{attrs:{"verificationData":_vm.layouts.data.verificationData,"ibv-provider":_vm.layouts.data.ibvProvider},on:{"back":_vm.backHandler}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }