<template>
  <div>
    <div class="main-content" v-scrollbar>
      <p class="guide-tip-title">Connect your bank so that we can determine your loan amount.</p>
      <p class="guide-title">This should be the checking account that receives your paycheck.</p>

      <s-section
        ref="bankSectionForm"
        v-model="layouts.data.currentData"
        :save-parameters="saveParameters"
        :action-parameters="actionParameters"
        @syncData="syncData"
        @syncFormSettings="shareFormSetting"
        data-template-code="D23142B4AB60B756D41B6A9C3EB51D4A"
        section-template-code="04AF5F16198A5C577CFEAA75CB209DFF">
      </s-section>

      <v-alert
        v-if="layouts.data.alertControl"
        class="mt-6"
        border="left"
        colored-border
        type="error"
        elevation="2"
      >
        Your information is incomplete or contains invalid data. Please click 'EDIT' to make changes.
      </v-alert>

      <div class="d-flex justify-center mt-5">
        <a @click="layouts.dialog.changeBankForm=true" class="f-s-20">Edit</a>
      </div>

    </div>

    <wap-bottom-sheet
      v-model="layouts.dialog.changeBankForm"
      :persistent="true"
      title="Edit"
      @cancel="layouts.dialog.changeBankForm = false"
      @confirm="onClickConfirm"
    >
      <v-card-text>
        <s-form
          ref="editableBankForm"
          primary-key="sectionId"
          :save-parameters="saveParameters"
          :action-parameters="actionParameters"
          :data-template-code="layouts.data.formCode"
          @saveCallBack="handleCallback"
          @handleBankMapping="bankMappingHandler"
          @loadingCallBack="loadingCallBack"
          :form-setting-from-section="layouts.data.editableFormSetting">
        </s-form>
      </v-card-text>
    </wap-bottom-sheet>

    <div class="action-container">
      <wap-btn
        :height="50"
        :theme="'normal'"
        :width="50"
        @click="onClickBackIcon"
      >
        <v-icon>navigate_before</v-icon>
      </wap-btn>
      <wap-btn
        :height="50"
        width="80%"
        :loading="layouts.mappingLoading"
        @click="onClickContinue"
      >Continue
      </wap-btn>
    </div>

    <wap-bottom-sheet
      v-model="layouts.dialog.resetRoutingNoTip"
      :persistent="true"
      title="Routing Number Not Found"
      :notification-sheet="true"
      @confirm="layouts.dialog.resetRoutingNoTip=false"
    >
        <div class="pa-3">
          Sorry, we couldn't find a related Bank Name for the provided routing number. Please verify the routing number and try again.
        </div>
      <div class="px-2">
      </div>
    </wap-bottom-sheet>
  </div>
</template>
<script>
import SForm from '@/components/base/SForm'
import WapBtn from '@/components/base/WapBtn'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import SSection from '@/components/base/SSection'
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'wap-guidance-bank',
  components: {
    SForm,
    WapBtn,
    WapBottomSheet,
    SSection
  },
  props: {
    actionParameters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saveParameters: {
        externalKey: 'sectionData',
        payload: {
          sectionId: this.actionParameters.sectionId
        }
      },
      layouts: {
        data: {
          formCode: 'D23142B4AB60B756D41B6A9C3EB51D4A',
          currentData: {},
          alertControl: false,
          editableFormSetting: {}
        },
        dialog: {
          resetRoutingNoTip: false,
          changeBankForm: false
        },
        loading: {
          update: false
        },
        mappingLoading: false
      }
    }
  },
  watch: {
    'layouts.dialog.changeBankForm': {
      handler (val) {
        if (val && tool.isNotEmpty(this.$refs.editableBankForm)) {
          this.$refs.editableBankForm.load()
        }
      }
    }
  },
  methods: {
    onClickBackIcon () {
      this.$emit('back')
    },
    syncData (result) {
      this.layouts.data.currentData = result
      this.$emit('verification', result)
    },
    shareFormSetting (data) {
      this.layouts.data.editableFormSetting = data
    },
    onClickContinue () {
      if (tool.isEmpty(this.layouts.data.currentData)) {
        return
      }
      this.$refs.bankSectionForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.$emit('continue')
        } else {
          this.layouts.data.alertControl = true
        }
      })
    },
    loadingCallBack (loading) {
      this.layouts.loading.update = loading
    },
    handleCallback (data) {
      this.$emit('verification', data)
      this.layouts.data.currentData = data
      this.$refs.editableBankForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.layouts.dialog.changeBankForm = false
          this.layouts.data.alertControl = false
        }
      })
    },
    bankMappingHandler (event) {
      switch (event) {
        case 'Map Loading':
          this.layouts.mappingLoading = true
          break
        case 'Complete':
          this.layouts.mappingLoading = false
          break
        case 'Not Find':
          this.layouts.mappingLoading = false
          this.layouts.dialog.resetRoutingNoTip = true
          break
        default:
          break
      }
    },
    onClickConfirm () {
      if (!this.layouts.loading.update) {
        this.$refs.editableBankForm.saveForm()
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.main-content
  & .guide-tip-title
    margin-bottom: 8px
    font-weight: 300

  & .guide-title
    font-size: 14px
    font-weight: 300
</style>
