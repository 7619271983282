<template>
  <div class="wap-select my-3">
    <v-text-field
      v-bind="properties"
      v-on="$listeners"
      v-model="pickerText"
      @click="onClickTextField"
      readonly
      hide-details="auto"
      class="f-w-300"
    >
      <template slot="label">
        {{ label }}<span v-if="necessarily" class="text-danger">*</span>
      </template>
    </v-text-field>
    <div class="error-message">{{ errorMessage }}</div>

    <v-bottom-sheet v-model="pickerVisible" persistent>
      <van-picker
        ref="picker"
        show-toolbar
        :columns="pickData"
      >
        <template v-slot:default>
          <div class="sheet-header px-3">
            <div class="sheet-left">
              <v-icon @click="pickerVisible = false">mdi-close</v-icon>
            </div>
            <div class="sheet-middle f-s-16 f-w-900">{{ label }}</div>
            <div @click="onConfirm" class="sheet-right">
              <v-icon >mdi-check</v-icon>
            </div>
          </div>
        </template>
      </van-picker>
    </v-bottom-sheet>
  </div>
</template>
<script>
import { Tool } from '@/assets/js/util'
import { Picker } from 'vant'
import 'vant/lib/popup/style/index'
import 'vant/lib/picker/style/index'
const tool = new Tool()
export default {
  name: 'wap-select',
  components: {
    [Picker.name]: Picker
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: null,
    properties: {
      type: Object,
      default: () => null
    },
    label: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    items: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ''
    },
    necessarily: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pickerVisible: false,
      pickData: [...this.items]
    }
  },
  computed: {
    pickerText () {
      let result = ''
      if (tool.isNotEmpty(this.items) && tool.isNotEmpty(this.value)) {
        for (const item of this.items) {
          if (this.value === item.value) {
            result = item.text
          }
        }
      }
      return result
    }
  },
  watch: {
    pickerVisible (val) {
      if (val) {
        this.pickData = [...this.items]
      }
    }
  },
  methods: {
    onClickTextField () {
      this.pickerVisible = true
    },
    onConfirm () {
      const selectedData = this.$refs.picker.getValues()
      if (tool.isEmpty(selectedData)) {
        if (tool.isNotEmpty(this.items) && tool.isNotEmpty(this.items[0]) && tool.isNotEmpty(this.items[0].value)) {
          this.$emit('change', this.items[0].value)
        }
      } else {
        this.$emit('change', selectedData[0].value)
      }
      this.pickerVisible = false
    }
  },
  mounted () {
  }
}
</script>
<style lang="sass">
  .wap-vue-picker
    background-color: rgba(4, 31, 56, 0.3) !important

    & .btn
      color: var(--theme_primary) !important
  .error-message
    margin-top: 5px
    display: flex
    color: red
    font-size: 12px
    width: 100%
    line-height: 12px
    word-break: break-word
    word-wrap: break-word

  .sheet-header
    display: flex
    justify-content: space-between
    align-items: center
    background-color: #ffffff
    width: 100%
    height: 44px
    border-radius: 12px 12px 0 0
  .sheet-left
    width: 25%
  .sheet-middle
    width: 50%
    text-align: center
  .sheet-right
    height: 24px
    width: 25%
    text-align: right

  .van-picker
    border-radius: 12px 12px 0 0
    .van-picker-column__wrapper
      padding: 0
  .v-dialog
    border-radius: 12px 12px 0 0!important
</style>
