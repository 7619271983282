<template>
  <div>
    <!--  展示IBV之前，先校验黑名单-->
    <template v-if="layouts.sectionDisplay.checkRiskData">
      <wap-guidance-check-data
        @reviewSuccess="handleSuccess"
      ></wap-guidance-check-data>
    </template>

    <!--  IBV的文本介绍，以及返回和继续的按钮-->
    <template v-else-if="layouts.sectionDisplay.DecisionLogicTips">
      <wap-ibv-introduction
        class="main-content" v-scrollbar
        :ibv-provider="ibvProvider"
      ></wap-ibv-introduction>
      <div class="action-container">
        <wap-btn :height="50" :theme="'normal'" :width="50" @click="onClickBackIcon">
          <v-icon>navigate_before</v-icon>
        </wap-btn>
        <wap-btn :height="50" width="80%" @click="onClickContinue">Continue</wap-btn>
      </div>
    </template>

    <!--  授权的页面，先授权后计算-->
    <template v-else-if="layouts.sectionDisplay.authorizationSection">
      <wap-decision-logic-authorization
        class="main-content" v-scrollbar
        :requestData="layouts.data.verificationData"
        :ibv-provider="ibvProvider"
        @backAction="onDecisionLogicBack"
      ></wap-decision-logic-authorization>
    </template>

    <!--  计算的页面  -->
    <template v-else-if="layouts.sectionDisplay.calculationPage">
      <wap-guidance-calculation
        class="main-content" v-scrollbar
        :requestData="layouts.data.verificationData"
        >
      </wap-guidance-calculation>
    </template>
  </div>
</template>
<script>
import { Tool, DataType } from '@/assets/js/util'
import WapDecisionLogicAuthorization from '@/views/guidance/WapDecisionLogicAuthorization'
import WapIbvIntroduction from '@/views/guidance/WapIbvIntroduction'
import WapGuidanceCalculation from '@/views/guidance/WapGuidanceCalculation'
import WapBtn from '@/components/base/WapBtn'
import WapGuidanceCheckData from '@/views/guidance/WapGuidanceCheckData'

const tool = new Tool()
export default {
  name: 'wap-guidance-decision-logic',
  components: {
    WapGuidanceCheckData,
    WapIbvIntroduction,
    WapDecisionLogicAuthorization,
    WapGuidanceCalculation,
    WapBtn
  },
  props: {
    verificationData: {
      type: Object,
      default: () => {
      }
    },
    ibvProvider: {
      type: Number
    }
  },
  data () {
    return {
      assist: {
        tool: tool,
        dataType: DataType
      },
      layouts: {
        data: {
          accountNoTail: '',
          routingNoTail: '',
          resultType: 0,
          iconsStyle: {},
          verificationData: this.verificationData,
          isShowConfirm: false,
          reminderTitleContent: '',
          portfolio: {
            reverseIconUrl: ''
          }
        },
        sectionDisplay: {
          checkRiskData: true,
          calculationPage: false,
          DecisionLogicTips: false,
          authorizationSection: false
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler: function () {
        this.layouts.data.portfolio.reverseIconUrl = this.$store.getters.getPortfolioJson.reverseIconUrl
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
  },
  methods: {
    onClickBackIcon () {
      this.$emit('back')
    },
    handleSuccess () {
      this.layouts.sectionDisplay.checkRiskData = false
      this.layouts.sectionDisplay.DecisionLogicTips = true
    },
    onClickContinue () {
      this.layouts.sectionDisplay.DecisionLogicTips = false
      this.layouts.sectionDisplay.authorizationSection = true
    },
    onDecisionLogicBack () {
      this.layouts.sectionDisplay.authorizationSection = false
      this.layouts.sectionDisplay.calculationPage = true
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
