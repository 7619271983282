<template>
  <div class="guidance">

    <div class="progress-step-content px-10">
      <div class="step-box"
           :style="e1 === 1 ? 'background: var(--theme_primary)' : 'background: transparent'"
      >
        <v-icon size="18" color="#FFFFFF">
          person
        </v-icon>
      </div>
      <span class="solid-line"></span>
      <div class="step-box"
           :style="(e1 === 2 ? 'background: var(--theme_primary)' : e1 > 2 ? 'background: transparent' : 'background: rgba(255, 255, 255, 0.2)')"
      >
        <v-icon size="18" :color="(e1 >= 2 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')">
          groups
        </v-icon>
      </div>
      <span class="solid-line"></span>
      <div class="step-box"
           :style="(e1 === 3 ? 'background: var(--theme_primary)' : e1 > 3 ? 'background: transparent' : 'background: rgba(255, 255, 255, 0.2)')"
      >
        <v-icon size="18" :color="(e1 >= 3 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')">
          account_balance
        </v-icon>
      </div>
      <span class="solid-line"></span>
      <div class="step-box"
           :style="e1 === 4 ? 'background: var(--theme_primary)' : 'background: rgba(255, 255, 255, 0.2)'"
      >
        <v-icon size="18" :color="(e1 === 4 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')">
          playlist_add_check
        </v-icon>
      </div>
    </div>

    <div class="main-panel">
      <wap-guidance-person
        v-if="e1 === 1 && assist.tool.isNotEmpty(layouts.data.personalSectionId)"
        v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_PERSONAL_INFORMATION.value, path: $route.path}"
        :actionParameters="{
                sectionId: layouts.data.personalSectionId
              }"
        @verification="verificationHandler"
        @continue="continueHandler"
      ></wap-guidance-person>

      <wap-guidance-employment
        v-if="e1 === 2"
        v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_EMPLOYMENT_INFORMATION.value, path: $route.path}"
        :actionParameters="{
                sectionId: layouts.data.employmentSectionId
              }"
        @back="backHandler"
        @verification="verificationHandler"
        @continue="continueHandler"
      ></wap-guidance-employment>

      <wap-guidance-bank
        v-if="e1 === 3"
        v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_BANK_INFORMATION.value, path: $route.path}"
        :actionParameters="{
                sectionId: layouts.data.bankSectionId
              }"
        @verification="verificationHandler"
        @back="backHandler"
        @continue="continueHandler"
      ></wap-guidance-bank>

      <wap-guidance-decision-logic
        v-if="e1 === 4"
        :verificationData="layouts.data.verificationData"
        :ibv-provider="layouts.data.ibvProvider"
        @back="backHandler"
      ></wap-guidance-decision-logic>
    </div>
  </div>
</template>

<script>
import { DataType, Tool } from '@/assets/js/util'
import WapGuidancePerson from '@/views/guidance/WapGuidancePerson'
import WapGuidanceEmployment from '@/views/guidance/WapGuidanceEmployment'
import WapGuidanceBank from '@/views/guidance/WapGuidanceBank'
import WapGuidanceDecisionLogic from '@/views/guidance/WapGuidanceDecisionLogic'
import { OnlineApi } from '@/api'

export default {
  name: 'wap-guidance',
  components: {
    WapGuidancePerson,
    WapGuidanceEmployment,
    WapGuidanceBank,
    WapGuidanceDecisionLogic
  },
  data () {
    return {
      e1: 1,
      assist: {
        dataType: DataType,
        tool: new Tool()
      },
      layouts: {
        data: {
          verificationData: {},
          ibvProvider: 0,
          personalSectionId: null,
          employmentSectionId: null,
          bankSectionId: null
        }
      }
    }
  },
  created () {
    this.$set(this.layouts.data.verificationData, 'portfolioId', this.$store.getters.getPortfolioId)
    this.getIbvProvider()
    this.getSectionId()
  },
  methods: {
    getIbvProvider () {
      OnlineApi.getProvider(this.$store.getters.getPortfolioId, (result) => {
        this.layouts.data.ibvProvider = result
      })
    },
    getSectionId () {
      OnlineApi.listSection((res) => {
        res.forEach((item) => {
          switch (item.configProperties.sectionName) {
            case 'Personal':
              this.layouts.data.personalSectionId = item.sectionId
              break
            case 'Employment':
              this.layouts.data.employmentSectionId = item.sectionId
              break
            case 'Bank Info':
              this.layouts.data.bankSectionId = item.sectionId
              break
            default:
              break
          }
        })
      })
    },
    backHandler () {
      this.e1 = this.e1 - 1
    },
    continueHandler () {
      this.e1 = this.e1 + 1
    },
    verificationHandler (parameter) {
      const allowedKeys = ['accountNo', 'routingNo', 'firstName', 'bankName', 'lastName', 'accountType', 'email', 'cellPhone']

      for (const key of Object.keys(parameter)) {
        if (allowedKeys.includes(key)) {
          this.$set(this.layouts.data.verificationData, key, parameter[key])
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.guidance
  & .main-panel
    margin-top: 65px
    margin-bottom: 74px

  & .progress-step-content
    position: fixed
    top: 0
    z-index: 1
    padding: 0 10px
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    height: 60px
    background-color: var(--theme_secondary)
    border-bottom-left-radius: 15px
    border-bottom-right-radius: 15px

  .step-box
    width: 28px
    height: 28px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center

  .solid-line
    height: 1px
    border: 1px dashed #eef1f8
    flex-grow: 0.5

</style>

<style lang="sass">
.guidance
  & .main-content
    padding: 24px

  & .action-container
    position: fixed
    bottom: 0
    z-index: 1
    padding: 12px
    display: flex
    justify-content: space-between
    width: 100%
    background: white // 避免苹果手机向上拉的时候，该部分无背景色
</style>
