<template>
  <div>
    <div v-if="layouts.processingDisplay">
      <div class="text-center f-s-20"> {{ ibvName }} is processing your request, please be patient and wait until it is
        done, thank you!
      </div>
      <div class="d-flex justify-center pa-10">
        <v-progress-circular
          :size="80"
          :width="10"
          color="var(--theme_secondary)"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>

    <div
      v-else
      class="mt-6"
      ref="iframeParent"
      :style="assist.tool.isEmpty(height) ? 'height: calc(100vh - 230px)' : 'height:' + height">
      <iframe
        id="iFrameHolder"
        :src="widgetUrl"
        class="height-full width-full no-border"
        style="background-color: transparent"
        frameborder="0"
      ></iframe>
    </div>

    <v-snackbar
      v-model="layouts.snackbar.display"
      top
      timeout="-1"
    >
      {{ layouts.snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="var(--theme_secondary)"
          class="f-w-700"
          text
          v-bind="attrs"
          @click="onClickRetry"
        >
          Retry
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { DataType, Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'wap-decision-logic-authorization',
  components: {
  },
  props: {
    requestData: {
      type: Object,
      default: () => {
      }
    },
    height: {
      type: String,
      default: ''
    },
    ibvProvider: {
      type: Number
    }
  },
  computed: {
    ibvName () {
      return tool.getEnumTextByValue(DataType.IbvProviderEnum, this.ibvProvider)
    }
  },
  data: () => ({
    ibvAuthorization: null,
    widgetUrl: null,
    requestCode: '',
    layouts: {
      data: {
        bankData: {},
        errorMessage: ''
      },
      processingDisplay: true,
      snackbar: {
        display: false,
        text: 'Uh oh! It looks like there is a problem with this request code. Please try again!'
      }
    },
    assist: {
      tool: tool
    },
    timeIn: null
  }),
  mounted () {
    this.initIbv()
    // CommonRequest.addCustomerBehaviorRecords(
    //   this.$route.path,
    //   DataType.EventTypeEnum.ENTER_PAGE.value,
    //   DataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_DL_AUTHORIZATION_IFRAME.value
    // )
  },
  beforeDestroy () {
    clearInterval(this.timeIn)
  },
  methods: {
    // checkBlackList () {
    //   OnlineApi.checkBlackList((data) => {
    //     // res 为false，说明不在黑名单内
    //     if (data.result) {
    //       this.$router.push('/notification/200')
    //     } else {
    //       this.initIbv()
    //     }
    //   }, () => {
    //   })
    // },
    initIbv () {
      // 1、 Create Request Code
      // 2、 Update Ibv Data
      // 3、 Check Request Code Status
      const request = {
        portfolioId: this.$store.getters.getPortfolioJson.portfolioId,
        providerEntity: this.ibvProvider,
        accountNumber: this.requestData.accountNo,
        routingNumber: this.requestData.routingNo,
        firstName: this.requestData.firstName,
        bankName: this.requestData.bankName,
        lastName: this.requestData.lastName,
        email: this.requestData.email,
        phone: this.requestData.cellPhone,
        accountType: Number(this.requestData.accountType)
      }
      OnlineApi.createRequestCode(request, (result) => {
        if (this.ibvProvider === DataType.IbvProviderEnum.CHIRP.value) {
          this.widgetUrl = 'https://chirp.digital/api/widget?requestCode=' + result.requestCode
          // this.$refs.iframeParent.style.marginLeft = '-24px'
          // this.$refs.iframeParent.style.marginRight = '-24px'
          // document.getElementById('iFrameHolder').style.width = document.body.offsetWidth + 'px'
        } else {
          this.widgetUrl = 'https://widget.decisionlogic.com/Service.aspx?requestCode=' + result.requestCode
        }
        this.layouts.processingDisplay = false
        this.requestCode = result.requestCode
        this.$store.commit('setRequestId', result.requestId) // 计算之前要用这个值
        this.createRequestCallBack(result)
      },
      () => {
        this.layouts.snackbar.display = true
        // this.layouts.data.errorMessage = result.message
        // this.layouts.dialogs.bankInfoReminder = true
      })
    },
    createRequestCallBack (result) {
      this.$nextTick(() => {
        const iframe = document.getElementById('iFrameHolder')
        if (iframe !== null) {
          iframe.onload = () => {
            // CommonRequest.addCustomerBehaviorRecords(
            //   this.$route.path,
            //   DataType.EventTypeEnum.LEAVE_PAGE.value,
            //   DataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_DL_AUTHORIZATION_IFRAME.value
            // )
          }
        }
      })
      if (tool.isNotEmpty(result.requestCode) && result.requestCode.toString().length === 6) {
        this.checkRequestStatus(result)
      } else {
        this.$emit('backAction')
      }
    },
    checkRequestStatus (result) {
      const count = 0
      this.timeIn = setInterval(() => {
        OnlineApi.getRequestStatus({
          requestId: result.requestId
        }, (result) => {
          this.getStatusCallBack(count, result)
        })
      }, 5000)
    },
    getStatusCallBack (count, result) {
      const status = Number.parseInt(result)
      if (DataType.IBVStatusEnum.LOGIN_VERIFIED.value === status || DataType.IBVStatusEnum.SUCCESSFUL.value === status) {
        this.$emit('backAction')
        clearInterval(this.timeIn)
      } else {
        count++
        // 10分钟
        if (count >= 120) {
          clearInterval(this.timeIn)
          this.$emit('backAction')
        }
      }
    },
    onClickRetry () {
      this.initIbv()
      this.layouts.snackbar.display = false
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
