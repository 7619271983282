<template>
  <div>
    <div class="ibv-text pt-4">
      <div class="f-s-18 f-w-500 text-center">
        We use <span class="f-w-700 primary-color" @click="onClickIntroduction">{{ ibvName }}</span> to connect your account
      </div>
      <div class="mt-6">
        <v-icon color="var(--theme_secondary)" size="19" class="m-b-5">mdi-connection</v-icon> Effortless Connection
      </div>
      <div class="ml-6 mt-1 text-left">
        <span class="primary-color" @click="onClickIntroduction">{{ ibvName }}</span> makes it safe and easy to connect to your account.
      </div>
      <div class="mt-6 color-000410">
        <v-icon color="var(--theme_secondary)" size="19" class="m-b-5">mdi-email-fast</v-icon> Instant Decision
      </div>
      <div class="ml-6 mt-1 text-left">
        You will receive an instant application decision once <span class="primary-color" @click="onClickIntroduction">{{ ibvName }}</span> connects your account.
      </div>
    </div>
    <div class="f-w-500 f-s-14 mt-5">What is <span @click="onClickIntroduction" class="primary-color">{{ ibvName }}?</span></div>
    <wap-bottom-sheet
      v-model="layouts.dialogs.dlIntroduction.display"
      :persistent="true"
      :title="`${ibvName} Introduction` "
      :notification-sheet="true"
      @confirm="layouts.dialogs.dlIntroduction.display=false"
    >
      <v-card-text class="f-s-12 f-w-400">
        {{ ibvName }} is a secured, private and encrypted service.
        It allows us to view a read-only copy of your bank statement which includes your recent Direct Deposits from your employer.
      </v-card-text>
    </wap-bottom-sheet>
  </div>
</template>

<script>
import WapBottomSheet from '@/components/base/WapBottomSheet'
import { Tool, DataType } from '@/assets/js/util'
const tool = new Tool()

export default {
  name: 'wap-ibv-introduction',
  components: {
    WapBottomSheet
  },
  props: {
    ibvProvider: {
      type: Number
    }
  },
  computed: {
    ibvName () {
      return tool.getEnumTextByValue(DataType.IbvProviderEnum, this.ibvProvider)
    }
  },
  data () {
    return {
      layouts: {
        dialogs: {
          dlIntroduction: {
            display: false
          }
        }
      }
    }
  },
  methods: {
    onClickIntroduction () {
      this.layouts.dialogs.dlIntroduction.display = true
    }
  }
}
</script>

<style lang="sass" scoped>
.ibv-text
  font-size: 13px
  font-weight: 300

.primary-color
  color: var(--theme_primary)

.secondary-color
  color: var(--theme_secondary)
.dialog-title
  line-height: 40px
</style>
