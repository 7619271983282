<template>
<div class="py-8 px-4 d-flex flex-column justify-space-between align-center height-400">
  <div class="f-s-18">Thank you for providing your information. We are currently reviewing it, and this process typically takes only a few seconds.</div>
  <v-progress-circular
    :size="120"
    :width="8"
    color="var(--theme_secondary)"
    indeterminate
  >
    <v-avatar color="transparent" size="100">
      <img v-if="layouts.data.portfolio.iconUrl" class="icon-url" :src="layouts.data.portfolio.iconUrl">
    </v-avatar>
  </v-progress-circular>
</div>
</template>

<script>
import { OnlineApi } from '@/api'

export default {
  name: 'WapGuidanceCheckData',
  data () {
    return {
      layouts: {
        data: {
          portfolio: {
            iconUrl: ''
          }
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler: function () {
        this.layouts.data.portfolio.iconUrl = this.$store.getters.getPortfolioJson.iconUrl
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    setTimeout(() => { this.checkData() }, 3000)
  },
  methods: {
    checkData () {
      OnlineApi.checkRiskInfo((res) => {
        if (!res.blackListRisk && !res.isMilitary && !res.litigantRisk) {
          this.$emit('reviewSuccess')
        } else {
          this.$router.push('/notification/301')
        }
      }, () => {})
    }
  }
}
</script>

<style scoped>

</style>
