<template>
  <div>
    <div class="main-content">
      <p class="guide-title">Please confirm your information</p>

      <s-section
        ref="employmentSectionForm"
        v-model="layouts.data.currentData"
        :save-parameters="saveParameters"
        :action-parameters="actionParameters"
        @syncData="syncData"
        @syncFormSettings="shareFormSetting"
        data-template-code="282E321E2F2337233E6363FF327C37B2"
        section-template-code="5FB543C5284927E58396114863BC5DC1">
      </s-section>

      <v-alert
        v-if="layouts.data.alertControl"
        class="mt-6"
        border="left"
        colored-border
        type="error"
        elevation="2"
      >
        Your information is incomplete or contains invalid data. Please click 'EDIT' to make changes.
      </v-alert>
      <div class="d-flex justify-center mt-5">
        <a @click="layouts.dialog.changeEmploymentForm = true" class="f-s-20">Edit</a>
      </div>

    </div>

    <wap-bottom-sheet
      v-model="layouts.dialog.changeEmploymentForm"
      :persistent="true"
      title="Edit"
      @cancel="layouts.dialog.changeEmploymentForm = false"
      @confirm="onClickConfirm"
    >
      <v-card-text>
        <s-form
          ref="employmentForm"
          primary-key="sectionId"
          :save-parameters="saveParameters"
          :action-parameters="actionParameters"
          :data-template-code="layouts.data.formCode"
          :form-setting-from-section="layouts.data.editableFormSetting"
          @saveCallBack="handleCallback"
          @loadingCallBack="loadingCallBack"
        ></s-form>
      </v-card-text>
    </wap-bottom-sheet>

    <div class="action-container">
      <wap-btn
        :height="50"
        :theme="'normal'"
        :width="50"
        @click="onClickBackIcon"
      >
        <v-icon>navigate_before</v-icon>
      </wap-btn>
      <wap-btn
        :height="50"
        width="80%"
        @click="onClickContinue"
      >Continue
      </wap-btn>
    </div>
  </div>
</template>
<script>
import SForm from '@/components/base/SForm'
import WapBtn from '@/components/base/WapBtn'
import SSection from '@/components/base/SSection'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'wap-guidance-employment',
  components: {
    SForm,
    WapBtn,
    SSection,
    WapBottomSheet
  },
  props: {
    actionParameters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saveParameters: {
        externalKey: 'sectionData',
        payload: {
          sectionId: this.actionParameters.sectionId
        }
      },
      layouts: {
        data: {
          formCode: '282E321E2F2337233E6363FF327C37B2',
          currentData: {},
          alertControl: false,
          editableFormSetting: {}
        },
        dialog: {
          changeEmploymentForm: false
        },
        loading: {
          update: false
        }
      }
    }
  },
  watch: {
    'layouts.dialog.changeEmploymentForm': {
      handler (val) {
        if (val && tool.isNotEmpty(this.$refs.employmentForm)) {
          this.$refs.employmentForm.load()
        }
      }
    }
  },
  methods: {
    syncData (result) {
      this.layouts.data.currentData = result
      this.$emit('verification', result)
    },
    shareFormSetting (data) {
      this.layouts.data.editableFormSetting = data
    },
    onClickBackIcon () {
      this.$emit('back')
    },
    onClickContinue () {
      if (tool.isEmpty(this.layouts.data.currentData)) {
        return
      }
      this.$refs.employmentSectionForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.$emit('continue')
        } else {
          this.layouts.data.alertControl = true
        }
      })
    },
    loadingCallBack (loading) {
      this.layouts.loading.update = loading
    },
    handleCallback (data) {
      this.layouts.data.currentData = data
      this.$refs.employmentForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.layouts.dialog.changeEmploymentForm = false
          this.layouts.data.alertControl = false
        }
      })
      this.$emit('verification', data)
    },
    onClickConfirm () {
      if (!this.layouts.loading.update) {
        this.$refs.employmentForm.saveForm()
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.main-content
  & .guide-title
    font-size: 14px
    font-weight: 300

</style>
