<template>
  <div>
    <div class="main-content">
      <p class="guide-title">Please confirm your information</p>

      <s-section
        ref="personalSectionForm"
        v-model="layouts.data.currentData"
        :save-parameters="saveParameters"
        :action-parameters="actionParameters"
        data-template-code="D6E1927171C8CF3D735964E0D5CF8B99"
        section-template-code="4E2BE461DE7C24BB0D885386F9D43256"
        @syncData="syncData"
        @syncFormSettings="shareFormSetting"
      ></s-section>

      <v-alert
        v-if="layouts.data.alertControl"
        class="mt-6"
        border="left"
        colored-border
        type="error"
        elevation="2"
      >
        Your information is incomplete or contains invalid data. Please click 'EDIT' to make changes.
      </v-alert>
      <div class="d-flex justify-center mt-5">
      <a @click="layouts.dialog.changePersonalForm=true" class="f-s-20">Edit</a>
      </div>
    </div>

    <wap-bottom-sheet
      v-model="layouts.dialog.changePersonalForm"
      :persistent="true"
      title="Edit"
      @cancel="layouts.dialog.changePersonalForm = false"
      @confirm="onClickConfirm"
    >
      <v-card-text>
        <s-form
          ref="personalForm"
          primary-key="sectionId"
          :save-parameters="saveParameters"
          :action-parameters="actionParameters"
          :data-template-code="layouts.data.formCode"
          :form-setting-from-section="layouts.data.editableFormSetting"
          @saveCallBack="handleCallback"
          @loadingCallBack="loadingCallBack"
        >
        </s-form>
      </v-card-text>
    </wap-bottom-sheet>

    <div class="action-container">
      <wap-btn
        :height="50"
        :theme="'normal'"
        :width="50"
        @click="onClickBackIcon"
      >
        <v-icon>navigate_before</v-icon>
      </wap-btn>
      <wap-btn
        :height="50"
        width="80%"
        @click="onClickContinue"
      >Continue
      </wap-btn>
    </div>
  </div>
</template>
<script>
import SForm from '@/components/base/SForm'
import WapBtn from '@/components/base/WapBtn'
import SSection from '@/components/base/SSection'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'wap-guidance-person',
  components: {
    SSection,
    SForm,
    WapBtn,
    WapBottomSheet
  },
  props: {
    actionParameters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saveParameters: {
        externalKey: 'sectionData',
        payload: {
          sectionId: this.actionParameters.sectionId
        }
      },
      layouts: {
        data: {
          formCode: 'D6E1927171C8CF3D735964E0D5CF8B99',
          currentData: {},
          alertControl: false,
          editableFormSetting: {}
        },
        dialog: {
          changePersonalForm: false
        },
        loading: {
          update: false
        }
      }
    }
  },
  watch: {
    'layouts.dialog.changePersonalForm': {
      handler (val) {
        if (val && tool.isNotEmpty(this.$refs.personalForm)) {
          this.$refs.personalForm.load()
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    syncData (result) {
      this.layouts.data.currentData = result
      this.$emit('verification', result)
    },
    shareFormSetting (data) {
      this.layouts.data.editableFormSetting = data
    },
    onClickContinue () {
      if (tool.isEmpty(this.layouts.data.currentData)) {
        return
      }
      this.$refs.personalSectionForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.$emit('continue')
        } else {
          this.layouts.data.alertControl = true
        }
      })
    },
    onClickBackIcon () {
      this.$router.go(-1)
    },
    loadingCallBack (loading) {
      this.layouts.loading.update = loading
    },
    handleCallback (data) {
      this.layouts.data.currentData = data
      this.$refs.personalForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.layouts.dialog.changePersonalForm = false
          this.layouts.data.alertControl = false
        }
      })
      this.$emit('verification', data)
    },
    onClickConfirm () {
      if (!this.layouts.loading.update) {
        this.$refs.personalForm.saveForm()
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.main-content
  & .guide-title
    font-size: 12px
    font-weight: 300
    color: #9CA0AB

  & .action-container
    display: flex
    justify-content: space-between
    padding: 12px 0
</style>
